import React from "react"
import Layout from "../../components/h/layout"

const HalloweenAPage = () => (
  <Layout>
    <h2 className="text-link">Some call it a bath, you call it a bocha.</h2>
    <h2>Before bed you do this so you’re not hauna.</h2>
  </Layout>
)

export default HalloweenAPage
